<template>
  <section class="section">
    <div v-if="loaded" class="container is-max-desktop">
      <div class="columns">
        <div class="column is-8 is-offset-2" id="channels">
          <div class="control">
            <input v-model="search" placeholder="Search..." class="input" @keyup.enter="onSearch" />
          </div>
          <div v-if="searchResults">
            <div class="result" v-for="result in searchResults" :key="result.id" @click="onAddChannel(result)">
                <img class="thumbnail" :src="result.img" alt="">
                {{ result.title }}
            </div>
          </div>
          <div v-for="channel in channels" :key="channel.id" class="channel is-flex is-align-items-center">
            <router-link class="thumbnail" :to="{ name: 'Videos', params: { channel: channel.id } }">
              <img :src="channel.img" alt="">
            </router-link>
            <div class="name is-flex-grow-1">
              <router-link :to="{ name: 'Videos', params: { channel: channel.id } }">
                {{ channel.title }}
              </router-link>
            </div>
            <div class="videos">
              <router-link :to="{ name: 'Videos', params: { channel: channel.id } }"> Videos </router-link>
            </div>
            <div class="playlists">
              <router-link :to="{ name: 'Playlists', params: { channel: channel.id } }"> Playlists </router-link>
            </div>
            <a href="#" class="button is-danger" @click.prevent="onDeleteChannel(channel.id)">Delete</a>
          </div>
        </div>
      </div>
      <!-- END CONTAINER -->
    </div>
    <div v-else>Loading...</div>
  </section>
</template>

<script>
import { getChannelsFb, saveChannelFb, deleteChannelFb } from '../lib/fb'
import { getChannels } from '../lib/yt'

export default {
  name: 'Home',

  data() {
    return {
      channels: [],
      loaded: false,
      search: '',
      searchResults: [],
    }
  },

  mounted() {
    this.load()
  },

  //------------------------
  // METHODS
  //------------------------

  methods: {
    async load() {
      this.channels = await getChannelsFb()
      this.loaded = true
    },

    //------------------------
    // HELPERS
    //------------------------

    //------------------------
    // LISTENERS
    //------------------------

    async onSearch() {
      this.searchResults = await getChannels(this.search)
    },

    async onAddChannel(result) {
      await saveChannelFb(result.id, result)
      this.channels = await getChannelsFb()
      this.searchResults = []
      this.search = ''
    },

    async onDeleteChannel(id) {
      await deleteChannelFb(id)
      this.channels = await getChannelsFb()
      this.searchResults = []
    },
  },

  //------------------------
  // COMPUTED
  //------------------------

  computed: {},
}
</script>

<style scoped>
.channel {
  padding: 10px;
  margin-top: 10px;
  background-color: #2b3e50;
  border-radius: 5px;
}

.channel .name {
  cursor: pointer;
}

.channel a {
  color: #fefefe;
}

.channel .videos {
  margin-right: 25px;
}

.channel .playlists {
  margin-right: 25px;
}

.result {
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
  background-color: #2b3e50;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.thumbnail {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
</style>
