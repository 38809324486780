<template>
  <section class="section">
    <div v-if="loaded" class="container is-max-desktop">
      <div class="columns">
        <div class="column is-8 is-offset-2" id="videos">
          <!-- SEARCH -->
          <div class="is-flex is-align-items-center">
            <div class="control is-flex-grow-1">
              <input v-model="search" placeholder="Search..." class="input" />
            </div>
          </div>
          <!-- VIDEOS -->
          <div
            v-for="video in filteredVideos"
            :key="video.id"
            :class="['video', 'is-flex', 'is-align-items-center', video.watched ? 'watched' : '']"
          >
            <div class="eye" @click="onWatched(video)">
              <span class="icon">
                <i class="fa-eye far"></i>
              </span>
            </div>
            <div class="eye" @click="onFav(video)">
              <span class="icon" v-if="video.fav">
                <i class="fa-star fas"></i>
              </span>
              <span class="icon" v-else>
                <i class="fa-star far"></i>
              </span>
            </div>
            <div>
              <div class="name" :title="video.description">
                <a :href="'https://youtube.com/watch?v=' + video.id" target="_blank">{{ video.title }}</a>
              </div>
              <div class="date">
                {{ video.date }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END CONTAINER -->
    </div>
    <div v-else>Loading...</div>
  </section>
</template>

<script>
import { getFavVideosFb, deleteVideoFb, saveVideoFb } from '../lib/fb'

export default {
  name: 'Videos',

  props: ['channel'],

  data() {
    return {
      videos: [],
      loaded: false,
      nextPage: '',
      search: '',
    }
  },

  mounted() {
    this.loadSavedVideos()
  },

  //------------------------
  // METHODS
  //------------------------

  methods: {
    async loadSavedVideos() {
      this.videos = await getFavVideosFb()
      this.loaded = true
    },

    //------------------------
    // LISTENERS
    //------------------------

    async onWatched(v) {
      if (v.watched) {
        v.watched = false
        await saveVideoFb(v.id, v)
      } else {
        v.watched = true
        await saveVideoFb(v.id, v)
      }
      await this.loadSavedVideos()
    },

    async onFav(v) {
      if (v.fav) {
        v.fav = false
        await saveVideoFb(v.id, v)
      } else {
        v.fav = true
        await saveVideoFb(v.id, v)
      }
      await this.loadSavedVideos()
    },

    //------------------------
    // HELPERS
    //------------------------
  },

  //------------------------
  // COMPUTED
  //------------------------

  computed: {
    filteredVideos() {
      return this.videos.filter(
        (v) => v.title.toLowerCase().includes(this.search.toLowerCase()) || v.description.toLowerCase().includes(this.search.toLowerCase())
      )
    },
  },
}
</script>

<style scoped>
.video {
  padding: 10px;
  margin-top: 10px;
  background-color: #2b3e50;
  border-radius: 5px;
}

.video .name a {
  cursor: pointer;
  color: white;
}

.video .date {
  opacity: 0.5;
  font-size: 0.8rem;
}

.video .eye {
  margin-right: 10px;
  cursor: pointer;
}

.watched {
  opacity: 0.2;
}

.btn-load {
  margin-left: 5px;
  margin-top: 20px;
}
</style>
