import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Videos from '../views/Videos.vue'
import FavVideos from '../views/FavVideos.vue'
import FavPlaylists from '../views/FavPlaylists.vue'
import Playlists from '../views/Playlists.vue'
import Login from '../views/Login.vue'
import { auth } from '@/firebase/config'

const requireAuth = (to, from, next) => {
  if (auth.currentUser) {
    next()
  } else {
    console.log('redirigido a /login')
    next({ name: 'Login' })
  }
}

const requireNoAuth = (to, from, next) => {
  console.log('user', auth.currentUser)
  if (!auth.currentUser) {
    console.log('next')
    next()
  } else {
    console.log('redirigido a Home')
    next({ name: 'Home' })
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/fav/videos',
    name: 'FavVideos',
    component: FavVideos,
    beforeEnter: requireAuth,
  },
  {
    path: '/videos/:channel',
    name: 'Videos',
    component: Videos,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/fav/playlists',
    name: 'FavPlaylists',
    component: FavPlaylists,
    beforeEnter: requireAuth,
  },
  {
    path: '/playlists/:channel',
    name: 'Playlists',
    component: Playlists,
    props: true,
    beforeEnter: requireAuth,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
