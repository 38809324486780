const key = 'AIzaSyDklstIy3FOwjHKVJ05M7lyINbFiu6CGEc'
const axios = require('axios')

export const getChannels = async (query) => {
  const res = await axios.get(`https://www.googleapis.com/youtube/v3/search?key=${key}&type=channel&part=snippet&maxResults=10&q=${query}`)

  

  return res.data.items.map((item) => {
    const title = item.snippet.title[0].toUpperCase() + item.snippet.title.substring(1)
    return { id: item.snippet.channelId, title: title, img: item.snippet.thumbnails.default.url }
  })
}

export const getPlaylists = async (channelId, nextPage = '') => {
  const res = await axios.get(
    `https://www.googleapis.com/youtube/v3/playlists?key=${key}&channelId=${channelId}&maxResults=50&part=snippet&pageToken=${nextPage}`
  )

  nextPage = res.data.nextPageToken

  const playlists = res.data.items.map((item) => {
    return { id: item.id, title: item.snippet.title, date: item.snippet.publishedAt }
  })

  return { playlists, nextPage }
}

export const getPlaylistVideos = async (playlistId, nextPage = '') => {
  const videos = []

  const res = await axios.get(
    `https://www.googleapis.com/youtube/v3/playlistItems?key=${key}&playlistId=${playlistId}&maxResults=50&part=snippet&pageToken=${nextPage}`
  )

  nextPage = res.data.nextPageToken

  res.data.items.map((item) => {
    videos.push({
      title: item.snippet.title,
      id: item.snippet.resourceId.videoId,
      date: item.snippet.publishedAt,
      description: item.snippet.description,
    })
  })

  return { videos, nextPage }
}

export const _getVideos = async (channelId, nextPage = '') => {
  const videos = []
  let i = 1

  // do {
  let res = await axios.get(
    `https://www.googleapis.com/youtube/v3/search?key=${key}&type=video&part=snippet&maxResults=50&channelId=${channelId}&order=date&pageToken=${nextPage}`
  )
  nextPage = res.data.nextPageToken
  res.data.items.forEach((item) => {
    videos.push({
      i,
      title: item.snippet.title,
      id: item.id.videoId,
      date: item.snippet.publishedAt,
      description: item.snippet.description,
    })
    i++
  })
  // } while (nextPage)

  return { videos, nextPage }
}
