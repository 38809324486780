import { db } from '../firebase/config'
import { doc, setDoc, addDoc, getDoc, collection, getDocs, orderBy, query, deleteDoc, where } from '@firebase/firestore'

export const getChannelsFb = async () => {
  const q = query(collection(db, 'channels'), orderBy('title', 'asc'))
  const res = await getDocs(q)
  const items = res.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  
  return items
}

export const saveChannelFb = async (id, item) => {
  setDoc(doc(db, 'channels', id), item)
}

export const deleteChannelFb = async (id) => {
  deleteDoc(doc(db, 'channels', id))
}

//----------------------------------

export const getVideosFb = async () => {
  const q = query(collection(db, 'videos'), orderBy('title', 'asc'))
  const res = await getDocs(q)
  const items = res.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return items
}

export const getFavVideosFb = async () => {
  const q = query(collection(db, 'videos'), where('fav', '==', true), orderBy('title', 'asc'))
  const res = await getDocs(q)
  const items = res.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return items
}

export const saveVideoFb = async (id, item) => {
  setDoc(doc(db, 'videos', id), item)
}

export const deleteVideoFb = async (id) => {
  deleteDoc(doc(db, 'videos', id))
}

//-----------------------------

export const getPlaylistsFb = async () => {
  const q = query(collection(db, 'playlists'), orderBy('title', 'asc'))
  const res = await getDocs(q)
  const items = res.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return items
}

export const getFavPlaylistsFb = async () => {
  const q = query(collection(db, 'playlists'), where('fav', '==', true), orderBy('title', 'asc'))
  const res = await getDocs(q)
  const items = res.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return items
}

export const savePlaylistFb = async (id, item) => {
  setDoc(doc(db, 'playlists', id), item)
}

export const deletePlaylistFb = async (id) => {
  deleteDoc(doc(db, 'playlists', id))
}