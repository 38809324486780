<template>
  <section class="section">
    <div v-if="loaded" class="container is-max-desktop">
      <div class="columns">
        <div class="column is-8 is-offset-2" id="playlists">
          <!-- SEARCH -->
          <div class="is-flex is-align-items-center">
            <div class="control is-flex-grow-1">
              <input v-model="search" placeholder="Search..." class="input" />
            </div>
          </div>
          <!-- PLAYLISTS -->
          <div
            v-for="playlist in filteredPlaylists"
            :key="playlist.id"
            :class="[isFav(playlist) ? 'playlist-fav' : '', 'playlist', 'is-flex', 'is-align-items-center']"
          >
            <div class="fav" @click="onFav(playlist)">
              <span v-if="isFav(playlist)" class="icon">
                <i class="fa-star fas"></i>
              </span>
              <span v-if="!isFav(playlist)" class="icon">
                <i class="fa-star far"></i>
              </span>
            </div>
            <div>
              <div class="name">
                <a :href="'https://youtube.com/playlist?list=' + playlist.id" target="_blank">{{ playlist.title }}</a>
              </div>
              <div class="date">
                {{ playlist.date }}
              </div>
            </div>
          </div>
          <!-- LOAD MORE -->
          <div class="is-flex is-justify-content-center">
            <div>
              <button v-if="nextPage != 'END'" class="button btn-load" @click.prevent="load50()">Load more</button>
            </div>
            <div>
              <button v-if="nextPage != 'END'" class="button btn-load" @click.prevent="loadAll()">Load all</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END CONTAINER -->
    </div>
    <div v-else>Loading...</div>
  </section>
</template>

<script>
import { getPlaylists } from '../lib/yt'
import { savePlaylistFb, getPlaylistsFb, deletePlaylistFb } from '../lib/fb'

export default {
  name: 'Playlists',

  props: ['channel'],

  data() {
    return {
      playlists: [],
      playlistsFb: [],
      loaded: false,
      search: '',
      nextPage: '',
    }
  },

  mounted() {
    this.load()
  },

  //------------------------
  // METHODS
  //------------------------

  methods: {
    async load() {
      await this.loadPlaylistsFb()
      await this.loadPlaylists()
    },

    async loadPlaylists() {
      // if (localStorage.getItem('PL-' + this.channel)) {
      //   this.playlists = JSON.parse(localStorage.getItem('PL-' + this.channel))
      //   this.loaded = true
      // } else {
      await this.loadAll(100)
      // }
    },

    async loadPlaylistsFb() {
      let res = await getPlaylistsFb()
      this.playlistsFb = res.map((p) => p.id)
    },

    //------------------------
    // LISTENERS
    //------------------------

    async onFav(playlist) {
      if (this.playlistsFb.includes(playlist.id)) {
        await deletePlaylistFb(playlist.id)
      } else {
        await savePlaylistFb(playlist.id, playlist)
      }
      await this.loadPlaylistsFb()
    },

    async refresh() {
      const res = await getPlaylists(this.channel)
      const tmp = []
      res.playlists.forEach((v) => {
        if (!this.playlists.find((x) => x.id == v.id)) {
          console.log('new playlist', v.title)
          tmp.push(v)
        }
      })
      this.playlists = tmp.concat(this.playlists)
      // localStorage.setItem('PL-' + this.channel, JSON.stringify(this.playlists))
    },

    async load50() {
      console.log('load 50')
      const res = await getPlaylists(this.channel, this.nextPage)
      res.playlists.forEach((v) => {
        this.playlists.push(v)
      })
      this.loaded = true
      if (res.nextPage) {
        this.nextPage = res.nextPage
      } else {
        this.nextPage = 'END'
      }
    },

    async loadAll(max = 999999) {
      console.log('load all')

      // localStorage.setItem('PL-' + this.channel, JSON.stringify([]))
      this.playlists = []
      this.nextPage = ''

      do {
        await this.load50()
        await this.delay(100)
      } while (this.nextPage != 'END' && this.playlists.length < max)

      // localStorage.setItem('PL-' + this.channel, JSON.stringify(this.playlists))
    },

    //------------------------
    // HELPERS
    //------------------------

    isFav(playlist) {
      return this.playlistsFb.includes(playlist.id)
    },

    delay(ms) {
      return new Promise((res) => setTimeout(res, ms))
    },
  },

  //------------------------
  // COMPUTED
  //------------------------

  computed: {
    filteredPlaylists() {
      return this.playlists.filter((v) => {
        return v.title.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
}
</script>

<style scoped>
.playlist {
  padding: 10px;
  margin-top: 10px;
  background-color: #2b3e50;
  border-radius: 5px;
}

.playlist.playlist-fav {
  background-color: #4e5d6c;
}

.playlist .name a {
  cursor: pointer;
  color: white;
}

.playlist .date {
  opacity: 0.5;
  font-size: 0.8rem;
}

.playlist .fav {
  margin-right: 10px;
  cursor: pointer;
}

.btn-load {
  margin-left: 5px;
  margin-top: 20px;
}
</style>
