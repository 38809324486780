<template>
  <section class="section">
    <div v-if="loaded" class="container is-max-desktop">
      <div class="columns">
        <div class="column is-8 is-offset-2" id="videos">
          <!-- SEARCH -->
          <div class="is-flex is-align-items-center">
            <div class="control is-flex-grow-1">
              <input v-model="search" placeholder="Search..." class="input" />
            </div>

            <div style="margin-left: 5px;">
              <button v-if="nextPage != 'END'" class="button" @click.prevent="loadAll()">Load all</button>
            </div>
          </div>
          <!-- VIDEOS -->
          <div
            v-for="video in filteredVideos"
            :key="video.id"
            :class="['video', 'is-flex', 'is-align-items-center', isWatched(video.id) ? 'watched' : '']"
          >
            <div class="eye" @click="onWatched(video)">
              <span class="icon">
                <i class="fa-eye far"></i>
              </span>
            </div>
            <div class="eye" @click="onFav(video)">
              <span class="icon" v-if="isFav(video.id)">
                <i class="fa-star fas"></i>
              </span>
              <span class="icon" v-else>
                <i class="fa-star far"></i>
              </span>
            </div>
            <div>
              <div class="name" :title="video.description">
                <a :href="'https://youtube.com/watch?v=' + video.id" target="_blank">{{ video.title }}</a>
              </div>
              <div class="date">
                {{ video.date }}
              </div>
            </div>
          </div>
          <!-- LOAD MORE -->
          <div class="is-flex is-justify-content-center">
            <div>
              <button v-if="nextPage != 'END'" class="button btn-load" @click.prevent="load50()">Load more</button>
            </div>
            <div>
              <button v-if="nextPage != 'END'" class="button btn-load" @click.prevent="loadAll()">Load all</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END CONTAINER -->
    </div>
    <div v-else>Loading...</div>
  </section>
</template>

<script>
import { getPlaylistVideos } from '../lib/yt'
import { getVideosFb, deleteVideoFb, saveVideoFb } from '../lib/fb'

export default {
  name: 'Videos',

  props: ['channel'],

  data() {
    return {
      videos: [],
      saved: [],
      loaded: false,
      nextPage: '',
      search: '',
    }
  },

  mounted() {
    this.loadVideos()
    this.loadSavedVideos()
  },

  //------------------------
  // METHODS
  //------------------------

  methods: {
    async loadVideos() {
      await this.loadAll(50)
    },

    async loadSavedVideos() {
      this.saved = await getVideosFb()
      console.log(this.saved)
    },

    //------------------------
    // LISTENERS
    //------------------------

    async load50() {
      console.log('load 50')
      const res = await getPlaylistVideos('UU' + this.channel.substring(2), this.nextPage)
      res.videos.forEach((v) => {
        this.videos.push(v)
      })
      this.loaded = true
      if (res.nextPage) {
        this.nextPage = res.nextPage
      } else {
        this.nextPage = 'END'
      }
    },

    async loadAll(max = 999999) {
      this.videos = []
      this.nextPage = ''

      do {
        await this.load50()
        await this.delay(100)
      } while (this.nextPage != 'END' && this.videos.length < max)
    },

    async onWatched(v) {
      if (this.isWatched(v.id)) {
        v.watched = false
        await saveVideoFb(v.id, v)
      } else {
        v.watched = true
        await saveVideoFb(v.id, v)
      }
      await this.loadSavedVideos()
    },

    async onFav(v) {
      if (this.isFav(v.id)) {
        v.fav = false
        await saveVideoFb(v.id, v)
      } else {
        v.fav = true
        await saveVideoFb(v.id, v)
      }
      await this.loadSavedVideos()
    },

    //------------------------
    // HELPERS
    //------------------------

    delay(ms) {
      return new Promise((res) => setTimeout(res, ms))
    },

    isFav(id) {
      const v = this.saved.find(v => v.id == id)
      if (v != null) {
        return v.fav == true
      } else {
        return false
      }
    },

    isWatched(id) {
      const v = this.saved.find(v => v.id == id)
      if (v != null) {
        return v.watched == true
      } else {
        return false
      }
    }
  },

  //------------------------
  // COMPUTED
  //------------------------

  computed: {
    filteredVideos() {
      return this.videos.filter(
        (v) => v.title.toLowerCase().includes(this.search.toLowerCase()) || v.description.toLowerCase().includes(this.search.toLowerCase())
      )
    },
  },
}
</script>

<style scoped>
.video {
  padding: 10px;
  margin-top: 10px;
  background-color: #2b3e50;
  border-radius: 5px;
}

.video .name a {
  cursor: pointer;
  color: white;
}

.video .date {
  opacity: 0.5;
  font-size: 0.8rem;
}

.video .eye {
  margin-right: 10px;
  cursor: pointer;
}

.watched {
  opacity: 0.2;
}

.btn-load {
  margin-left: 5px;
  margin-top: 20px;
}
</style>
